import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportArchive } from '../../components/library'
import theme from '../../theme'
import { DateTime } from 'luxon'

const yesterday = DateTime.now().plus({ days: -1.3 })

const data = [
    {
        time: yesterday.toISO(),
        symptoms: [
            {
                time: yesterday.plus({minutes: 56}).toISO(),
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: yesterday.plus({minutes: -56}).toISO(),
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: yesterday.plus({minutes: -120}).toISO(),
                location: 'Mall',
            },
        ],
        level: 2,
        to: '/components',
    },
    {
        time: '2021-06-01T00:00:00.923-07:00',
        symptoms: [
            {
                time: '2021-06-12T17:20:50.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: '2021-06-12T15:10:50.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: '2021-06-12T14:19:50.923-07:00',
                location: 'Mall',
            },
            {
                time: '2021-06-12T10:16:06.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
        ],
        level: 3,
        to: '/components',
    },
    {
        time: '2021-06-08T00:00:00.923-07:00',
        symptoms: [
            {
                time: '2021-06-08T17:20:50.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: '2021-06-08T15:10:50.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: '2021-06-08T14:19:50.923-07:00',
                location: 'Mall',
            },
            {
                time: '2021-06-08T10:16:06.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
            {
                time: '2021-06-08T10:16:06.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
        ],
        level: 0,
        to: '/components',
    },
    {
        time: '2021-06-14T11:20:50.923-07:00',
        symptoms: null,
        level: 4,
        to: '/components',
    },
    {
        time: '2021-06-21T00:00:00.923-07:00',
        symptoms: [
            {
                time: '2021-06-21T17:20:50.923-07:00',
                location: 'Playground',
                trigger: 'Pollen'
            },
        ],
        level: 4,
        to: '/components',
    },
]


function section() {
    return (
        <LibraryWrapper>
            <ReportArchive data={data} title="SYMPTOM REPORTS" />
        </LibraryWrapper>
    )
}
export default section
